#globalSearchInfiniteTable {
    position: absolute;
    z-index: 1;
    background-color: white;
    width: 330px;
    right: 15px;
}

#jobSearchTable {
    position: absolute;
    z-index: 1;
    background-color: white;
    width: 465px;
}
